@media only screen and (min-width: 1366px) {
  /* styles for browsers larger than 960px; */
  .video-wrapper.paid {
    width: 120%;
    height: 30%;
    transform: translateX(-7em);
  }
}

@media only screen and (max-width: 1366px) {
  /* styles for browsers larger than 960px; */
  .video-wrapper {
    width: 100%;
    height: 0;
  }
}

.video-wrapper {
  background-color: rgba(243, 243, 243, 0.541);
  position: relative;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.w-100 {
  width: 100%;
}

.mh-50 {
  min-height: 50vh;
}

.video-youtube {
  pointer-events: none;
}
